<template>
  <div data-cy="asset-accounts-credit-notes-page">
    <TabTitle class="mb-4" icon="fa-file-lines" action-position="right">
      <template>Misc Files and Invoices</template>
      <template #sub-title
        >Manage any files and invoices related to this account. Files in this area will not be used in any consumption or financial reports.</template
      >
      <template #actions>
        <button
          v-if="$permissions.write('account', selectedAccount)"
          class="btn btn-primary"
          data-cy="upload-credit-note-btn"
          @click.prevent="showForm = showForm ? false : 'create'"
        >
          Upload File
        </button>
      </template>
    </TabTitle>

    <div v-if="showForm === 'create'" data-cy="credit-note-add" class="block">
      <div class="block-content bg-body-light">
        <CreditNoteForm v-model="form" @close="showForm = false" />
        <div class="btn btn-primary btn-block mb-4" :class="{ disabled: loading.submit }" @click.prevent="onCreateCreditNote">
          <span v-if="!loading.submit">Submit</span>
          <span v-else><i class="fa fa-spinner fa-spin"></i> Submitting...</span>
        </div>
      </div>
    </div>
    <div v-if="loadingAction.listCreditNotes || loadingAction.removeCreditNotes" class="d-flex justify-content-center mb-5">
      <div class="text-center">
        <div class="spinner-border spinner-lg text-secondary mb-4" role="status">
          <span class="sr-only">Loading files...</span>
        </div>
        <div class="font-w600">Loading files...</div>
      </div>
    </div>
    <div v-else-if="sortedCreditNotes.length > 0 && showForm !== 'create'" id="accordion" role="tablist">
      <div v-for="creditNote in sortedCreditNotes" :key="creditNote._id" class="block block-rounded mb-4">
        <div :id="`accordion_h${creditNote._id}`" class="block-header block-header-default" role="tab">
          <div class="d-flex justify-content-between align-items-center">
            <a
              class="font-w600 collapsed"
              data-toggle="collapse"
              data-parent="#accordion"
              :href="`#accordion_a${creditNote._id}`"
              @click.prevent="showForm === creditNote._id ? (showForm = false) : (showForm = creditNote._id)"
            >
              <div class="mb-1">{{ creditNote.date | date }}</div>
              <div>
                <span class="d-block d-xl-inline mr-4">{{ creditNote.name }}</span>
                <span v-if="typeof creditNote.netAmount !== 'undefined' && !$auth.settings.hideCostData" class="mr-4">{{
                  creditNote.netAmount | round | numberFormat(2)
                }}</span>
              </div>
              <div class="badge badge-info text-uppercase">{{ creditNote.type }}</div>
            </a>
          </div>
          <div class="d-flex align-items-center">
            <span v-if="loading.paid[creditNote._id]"><i class="fa fa-spinner fa-spin mr-2"></i></span>
            <div class="custom-control custom-checkbox custom-control-inline" :class="{ 'custom-control-success': creditNote.isPaid }">
              <input
                :id="`creditNote-paid-${creditNote._id}`"
                type="checkbox"
                class="custom-control-input"
                :disabled="loading.paid[creditNote._id] || !$permissions.write('account', selectedAccount)"
                :name="`creditNote-paid-${creditNote._id}`"
                :checked="creditNote.isPaid"
                @input="e => onClickPaid(creditNote, e.target.checked)"
              />
              <label class="custom-control-label" :class="{ 'text-success': creditNote.isPaid }" :for="`creditNote-paid-${creditNote._id}`"
                >Paid</label
              >
            </div>

            <button
              class="btn btn-link font-w600"
              :class="{ disabled: !creditNote.s3Key }"
              @click.prevent="() => onClickDownloadCreditNote(creditNote)"
            >
              <i class="fa fa-fw fa-file-pdf"></i> {{ loadingAction.downloadCreditNote === creditNote._id ? 'Loading...' : 'PDF' }}
            </button>

            <button
              v-if="$permissions.write('account', selectedAccount)"
              class="btn btn-link font-w600"
              @click.prevent="() => onClickConvertCreditNote(creditNote)"
            >
              <i class="fa fa-fw fa-file-pdf"></i>
              {{ loadingAction.convertCreditNoteToInvoice === creditNote._id ? 'Moving to Invoices...' : 'Move to Invoices' }}
            </button>

            <a
              v-if="$permissions.write('account', selectedAccount)"
              class="font-w600 collapsed btn btn-link"
              data-toggle="collapse"
              data-parent="#accordion"
              :href="`#accordion_a${creditNote._id}`"
              @click.prevent="showForm = showForm === creditNote._id ? false : creditNote._id"
            >
              <i class="fa fa-fw fa-pencil"></i> Edit
            </a>
            <button
              v-if="$permissions.write('account', selectedAccount)"
              class="btn btn-link text-danger font-w600"
              :disabled="loadingAction.removeCreditNote"
              @click.prevent="modals.delete = creditNote"
            >
              <i class="fa fa-fw fa-trash"></i> Delete
            </button>
          </div>
        </div>
        <div
          v-if="showForm === creditNote._id"
          :id="`accordion_a${creditNote._id}`"
          class="collapse"
          role="tabpanel"
          aria-labelledby="accordion_h2"
          data-parent="#accordion"
        >
          <div v-if="$permissions.write('account', selectedAccount)" class="block-content bg-body-light">
            <CreditNoteForm v-model="creditNoteUpdate[creditNote._id]" type="edit" />
            <div class="row push">
              <div class="col-lg-4"></div>
              <div class="col-lg-8 col-xl-5">
                <button class="btn btn-alt-warning mr-3" @click="showForm = false">
                  <span><i class="fa fa-xmark-circle mr-1"></i> Close </span>
                </button>
                <button class="btn btn-primary mr-3" @click.prevent="onEditCreditNote(creditNote._id)">
                  <span v-if="loading.submit"><i class="fa fa-spin fa-spinner mr-1"></i> Submitting...</span>
                  <span v-else><i class="fa fa-check-circle mr-1"></i> Submit</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="!showForm" class="alert alert-warning" role="alert">
      <p class="mb-0">
        No files uploaded.
        <a href="#" @click.prevent="showForm = false">Click here to get started.</a>
      </p>
    </div>
    <ConfirmModal
      :open="modals.delete"
      title="Delete File"
      :text="`You are about to delete file: <strong>${modals.delete.name}</strong>`"
      @close="modals.delete = false"
      @submit="onDeleteCreditNote"
    />
    <ConfirmModal :open="!!modals.pdf" title="PDF Download" @close="modals.pdf = false">
      <div class="d-flex mb-3">Choose which PDF you would like to download</div>
      <template v-slot:footer>
        <button class="btn btn-sm btn-light" type="button" @click.prevent="modals.pdf = false">Close</button>
        <button type="button" class="btn btn-sm btn-primary" data-bs-dismiss="modal" @click="downloadCreditNote('stampedUrl')">
          <i class="fa fa-stamp fa-fw"></i> Stamped
        </button>
        <button type="button" class="btn btn-sm btn-primary" data-bs-dismiss="modal" @click="downloadCreditNote('url')">
          <i class="fa fa-file-invoice fa-fw"></i> Original
        </button>
      </template>
    </ConfirmModal>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import ConfirmModal from '@/components/ConfirmModal';
import CreditNoteForm from '@/components/forms/CreditNoteForm';
import TabTitle from '@/components/base/TabTitle';

const initialForm = {
  name: '',
  s3Key: null,
  date: new Date(),
  type: 'creditNote',
  netAmount: 0,
  taxAmount: 0,
  isPaid: false,
  fileName: '',
  file: null,
  stampDate: new Date(),
  startDate: null,
  endDate: null,
  totalUnits: 0,
  totalWaterVolume: 0,
  totalWasteVolume: 0,
  totalWaterCost: 0,
  totalWasteCost: 0
};

export default {
  name: 'AssetAccountCreditNotes',
  components: {
    ConfirmModal,
    CreditNoteForm,
    TabTitle
  },
  data() {
    return {
      form: { ...initialForm },
      creditNoteUpdate: {},
      modals: {
        delete: false,
        pdf: false
      },
      showForm: false,
      loading: {
        submit: false,
        paid: {}
      }
    };
  },
  computed: {
    ...mapGetters({
      asset: 'asset/asset',
      selectedAccount: 'asset/selectedAccount',
      loadingAction: 'account/loadingAction',
      account: 'account/account'
    }),
    sortedCreditNotes() {
      let sorted = this.account.creditNotes || [];

      if (this.search) {
        sorted = sorted.filter(creditNote => creditNote.name.includes(this.search));
      }

      sorted.sort((a, b) => (a.date ? new Date(b.date) - new Date(a.date) : 0));

      return sorted;
    }
  },
  async mounted() {
    await this.refresh();
    this.getCompany({ id: this.selectedAccount.companyId });
  },
  methods: {
    ...mapActions({
      getInvoicesByAccount: 'asset/getInvoicesByAccount',
      updateInvoice: 'asset/updateAccountInvoice',
      listCreditNotes: 'account/listCreditNotes',
      uploadCreditNote: 'account/uploadCreditNote',
      createCreditNote: 'account/createCreditNote',
      updateCreditNote: 'account/updateCreditNote',
      removeCreditNote: 'account/removeCreditNote',
      getDownloadUrls: 'account/downloadCreditNote',
      convertCreditNoteToInvoice: 'account/convertCreditNoteToInvoice',
      createStampedCreditNote: 'account/stampCreditNote',
      getCompany: 'company/get'
    }),
    ...mapMutations({
      setCreditUploadLoading: 'account/SET_UPLOAD_CREDIT_LOADING'
    }),
    async refresh() {
      await this.listCreditNotes({ id: this.selectedAccount._id });
      this.form = { ...initialForm };
      this.account.creditNotes.map(creditNote => {
        this.creditNoteUpdate[creditNote._id] = {
          ...creditNote,
          file: null
        };
      });
    },
    async onClickPaid(creditNote, isChecked) {
      this.$set(this.loading.paid, creditNote._id, true);
      await this.updateCreditNote({ id: this.selectedAccount._id, creditNoteId: creditNote._id, data: { isPaid: isChecked } });
      this.$set(this.loading.paid, creditNote._id, false);
    },
    async onClickMoveToInvoice(creditNote) {
      const update = await this.updateInvoice({ id: creditNote._id, data: { isCreditNote: false } });
      if (update) {
        this.$toasted.success('Credit note moved to invoices.', { position: 'bottom-center', duration: 3000 });
      } else {
        this.$toasted.error('Could not move file to invoices.', { position: 'bottom-center', duration: 3000 });
      }
      this.getInvoicesByAccount({ id: this.$route.params.accountId, data: { params: { $deleted: true } } });
    },
    async onClickDownloadCreditNote(creditNote) {
      try {
        const downloadUrls = await this.getDownloadUrls({ id: this.selectedAccount._id, creditNoteId: creditNote._id });
        this.modals.pdf = { downloadUrls };
      } catch (e) {
        this.$toasted.error(e.errorMsg || 'Could not download file', { position: 'bottom-center', duration: 3000 });
      }
    },
    downloadCreditNote(type, downloadUrls = this.modals.pdf.downloadUrls, fileName = this.modals.pdf.fileName) {
      const link = document.createElement('a');
      link.target = '_blank';
      link.href = type ? downloadUrls[type] : downloadUrls.url || downloadUrls.stampedUrl;
      link.download = fileName;
      link.click();
      URL.revokeObjectURL(link.href);
      this.modals.pdf = false;
    },
    async onDeleteCreditNote() {
      const deleteCreditNote = await this.removeCreditNote({ id: this.selectedAccount._id, creditNoteId: this.modals.delete._id });
      await this.refresh();

      this.modals.delete = false;
      if (Array.isArray(deleteCreditNote)) {
        this.$toasted.success('Credit note deleted successfully.', { position: 'bottom-center', duration: 3000 });
      } else {
        this.$toasted.error('Could not delete file.', { position: 'bottom-center', duration: 3000 });
      }
    },
    async onClickConvertCreditNote(creditNote) {
      const invoice = await this.convertCreditNoteToInvoice({ id: this.selectedAccount._id, creditNoteId: creditNote._id });

      if (invoice._id) {
        this.$toasted.success('Credit note has been successfully moved to invoices.', { position: 'bottom-center', duration: 3000 });
        await this.refresh();
        this.getInvoicesByAccount({ id: this.$route.params.accountId, data: { params: { $deleted: true } } });
      }
    },
    async onEditCreditNote(id) {
      this.loading.submit = true;
      const creditNote = { ...this.creditNoteUpdate[id] };

      if (creditNote.file) {
        this.setCreditUploadLoading(true);

        const upload = await this.uploadCreditNote({ id: this.selectedAccount._id, data: creditNote.file.file });

        if (!upload.s3Key) {
          this.setCreditUploadLoading(false);
          this.loading.submit = false;
          return this.$toasted.error('There was an error uploading the Credit Note file', { position: 'bottom-center', duration: 3000 });
        }

        creditNote.s3Key = upload.s3Key;
        creditNote.fileName = creditNote.file.fileName;
      }

      const data = {
        ...creditNote,
        _id: undefined,
        file: null
      };

      const creditNoteUpdate = await this.updateCreditNote({ id: this.selectedAccount._id, creditNoteId: creditNote._id, data });

      this.setCreditUploadLoading(false);
      this.loading.submit = false;

      if (Array.isArray(creditNoteUpdate) && creditNoteUpdate.length > 0) {
        this.$toasted.success('Credit note updated successfully.', { position: 'bottom-center', duration: 3000 });
        this.showForm = false;
        await this.refresh();
      } else {
        this.$toasted.error('Could not update file.', { position: 'bottom-center', duration: 3000 });
      }
    },
    async onCreateCreditNote() {
      this.loading.submit = true;

      const form = { ...this.form };

      if (form.file) {
        this.setCreditUploadLoading(true);

        const upload = await this.uploadCreditNote({ id: this.selectedAccount._id, data: form.file.file });

        if (!upload.s3Key) {
          this.setCreditUploadLoading(false);
          this.loading.submit = false;
          return this.$toasted.error('There was an error uploading the Credit Note file', { position: 'bottom-center', duration: 3000 });
        }

        form.s3Key = upload.s3Key;
        form.fileName = this.form.file.fileName;
      }

      const data = {
        ...form,
        _id: undefined,
        file: null
      };

      const creditNoteUpload = await this.createCreditNote({ id: this.selectedAccount._id, data });

      this.setCreditUploadLoading(false);

      this.loading.submit = false;

      if (Array.isArray(creditNoteUpload) && creditNoteUpload.length > 0) {
        this.$toasted.success('Misc document uploaded successfully.', { position: 'bottom-center', duration: 3000 });
        this.showForm = false;
        await this.refresh();
      } else {
        this.$toasted.error('Could not create misc file.', { position: 'bottom-center', duration: 3000 });
      }
    }
  }
};
</script>
