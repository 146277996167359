var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "data-cy": "asset-accounts-credit-notes-page"
    }
  }, [_c('TabTitle', {
    staticClass: "mb-4",
    attrs: {
      "icon": "fa-file-lines",
      "action-position": "right"
    },
    scopedSlots: _vm._u([{
      key: "sub-title",
      fn: function fn() {
        return [_vm._v("Manage any files and invoices related to this account. Files in this area will not be used in any consumption or financial reports.")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_vm.$permissions.write('account', _vm.selectedAccount) ? _c('button', {
          staticClass: "btn btn-primary",
          attrs: {
            "data-cy": "upload-credit-note-btn"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              _vm.showForm = _vm.showForm ? false : 'create';
            }
          }
        }, [_vm._v(" Upload File ")]) : _vm._e()];
      },
      proxy: true
    }])
  }, [[_vm._v("Misc Files and Invoices")]], 2), _vm.showForm === 'create' ? _c('div', {
    staticClass: "block",
    attrs: {
      "data-cy": "credit-note-add"
    }
  }, [_c('div', {
    staticClass: "block-content bg-body-light"
  }, [_c('CreditNoteForm', {
    on: {
      "close": function close($event) {
        _vm.showForm = false;
      }
    },
    model: {
      value: _vm.form,
      callback: function callback($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }), _c('div', {
    staticClass: "btn btn-primary btn-block mb-4",
    class: {
      disabled: _vm.loading.submit
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onCreateCreditNote($event);
      }
    }
  }, [!_vm.loading.submit ? _c('span', [_vm._v("Submit")]) : _c('span', [_c('i', {
    staticClass: "fa fa-spinner fa-spin"
  }), _vm._v(" Submitting...")])])], 1)]) : _vm._e(), _vm.loadingAction.listCreditNotes || _vm.loadingAction.removeCreditNotes ? _c('div', {
    staticClass: "d-flex justify-content-center mb-5"
  }, [_vm._m(0)]) : _vm.sortedCreditNotes.length > 0 && _vm.showForm !== 'create' ? _c('div', {
    attrs: {
      "id": "accordion",
      "role": "tablist"
    }
  }, _vm._l(_vm.sortedCreditNotes, function (creditNote) {
    return _c('div', {
      key: creditNote._id,
      staticClass: "block block-rounded mb-4"
    }, [_c('div', {
      staticClass: "block-header block-header-default",
      attrs: {
        "id": "accordion_h".concat(creditNote._id),
        "role": "tab"
      }
    }, [_c('div', {
      staticClass: "d-flex justify-content-between align-items-center"
    }, [_c('a', {
      staticClass: "font-w600 collapsed",
      attrs: {
        "data-toggle": "collapse",
        "data-parent": "#accordion",
        "href": "#accordion_a".concat(creditNote._id)
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          _vm.showForm === creditNote._id ? _vm.showForm = false : _vm.showForm = creditNote._id;
        }
      }
    }, [_c('div', {
      staticClass: "mb-1"
    }, [_vm._v(_vm._s(_vm._f("date")(creditNote.date)))]), _c('div', [_c('span', {
      staticClass: "d-block d-xl-inline mr-4"
    }, [_vm._v(_vm._s(creditNote.name))]), typeof creditNote.netAmount !== 'undefined' && !_vm.$auth.settings.hideCostData ? _c('span', {
      staticClass: "mr-4"
    }, [_vm._v(_vm._s(_vm._f("numberFormat")(_vm._f("round")(creditNote.netAmount), 2)))]) : _vm._e()]), _c('div', {
      staticClass: "badge badge-info text-uppercase"
    }, [_vm._v(_vm._s(creditNote.type))])])]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_vm.loading.paid[creditNote._id] ? _c('span', [_c('i', {
      staticClass: "fa fa-spinner fa-spin mr-2"
    })]) : _vm._e(), _c('div', {
      staticClass: "custom-control custom-checkbox custom-control-inline",
      class: {
        'custom-control-success': creditNote.isPaid
      }
    }, [_c('input', {
      staticClass: "custom-control-input",
      attrs: {
        "id": "creditNote-paid-".concat(creditNote._id),
        "type": "checkbox",
        "disabled": _vm.loading.paid[creditNote._id] || !_vm.$permissions.write('account', _vm.selectedAccount),
        "name": "creditNote-paid-".concat(creditNote._id)
      },
      domProps: {
        "checked": creditNote.isPaid
      },
      on: {
        "input": function input(e) {
          return _vm.onClickPaid(creditNote, e.target.checked);
        }
      }
    }), _c('label', {
      staticClass: "custom-control-label",
      class: {
        'text-success': creditNote.isPaid
      },
      attrs: {
        "for": "creditNote-paid-".concat(creditNote._id)
      }
    }, [_vm._v("Paid")])]), _c('button', {
      staticClass: "btn btn-link font-w600",
      class: {
        disabled: !creditNote.s3Key
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return function () {
            return _vm.onClickDownloadCreditNote(creditNote);
          }($event);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-file-pdf"
    }), _vm._v(" " + _vm._s(_vm.loadingAction.downloadCreditNote === creditNote._id ? 'Loading...' : 'PDF') + " ")]), _vm.$permissions.write('account', _vm.selectedAccount) ? _c('button', {
      staticClass: "btn btn-link font-w600",
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return function () {
            return _vm.onClickConvertCreditNote(creditNote);
          }($event);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-file-pdf"
    }), _vm._v(" " + _vm._s(_vm.loadingAction.convertCreditNoteToInvoice === creditNote._id ? 'Moving to Invoices...' : 'Move to Invoices') + " ")]) : _vm._e(), _vm.$permissions.write('account', _vm.selectedAccount) ? _c('a', {
      staticClass: "font-w600 collapsed btn btn-link",
      attrs: {
        "data-toggle": "collapse",
        "data-parent": "#accordion",
        "href": "#accordion_a".concat(creditNote._id)
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          _vm.showForm = _vm.showForm === creditNote._id ? false : creditNote._id;
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-pencil"
    }), _vm._v(" Edit ")]) : _vm._e(), _vm.$permissions.write('account', _vm.selectedAccount) ? _c('button', {
      staticClass: "btn btn-link text-danger font-w600",
      attrs: {
        "disabled": _vm.loadingAction.removeCreditNote
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          _vm.modals.delete = creditNote;
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-fw fa-trash"
    }), _vm._v(" Delete ")]) : _vm._e()])]), _vm.showForm === creditNote._id ? _c('div', {
      staticClass: "collapse",
      attrs: {
        "id": "accordion_a".concat(creditNote._id),
        "role": "tabpanel",
        "aria-labelledby": "accordion_h2",
        "data-parent": "#accordion"
      }
    }, [_vm.$permissions.write('account', _vm.selectedAccount) ? _c('div', {
      staticClass: "block-content bg-body-light"
    }, [_c('CreditNoteForm', {
      attrs: {
        "type": "edit"
      },
      model: {
        value: _vm.creditNoteUpdate[creditNote._id],
        callback: function callback($$v) {
          _vm.$set(_vm.creditNoteUpdate, creditNote._id, $$v);
        },
        expression: "creditNoteUpdate[creditNote._id]"
      }
    }), _c('div', {
      staticClass: "row push"
    }, [_c('div', {
      staticClass: "col-lg-4"
    }), _c('div', {
      staticClass: "col-lg-8 col-xl-5"
    }, [_c('button', {
      staticClass: "btn btn-alt-warning mr-3",
      on: {
        "click": function click($event) {
          _vm.showForm = false;
        }
      }
    }, [_vm._m(1, true)]), _c('button', {
      staticClass: "btn btn-primary mr-3",
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.onEditCreditNote(creditNote._id);
        }
      }
    }, [_vm.loading.submit ? _c('span', [_c('i', {
      staticClass: "fa fa-spin fa-spinner mr-1"
    }), _vm._v(" Submitting...")]) : _c('span', [_c('i', {
      staticClass: "fa fa-check-circle mr-1"
    }), _vm._v(" Submit")])])])])], 1) : _vm._e()]) : _vm._e()]);
  }), 0) : !_vm.showForm ? _c('div', {
    staticClass: "alert alert-warning",
    attrs: {
      "role": "alert"
    }
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" No files uploaded. "), _c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        _vm.showForm = false;
      }
    }
  }, [_vm._v("Click here to get started.")])])]) : _vm._e(), _c('ConfirmModal', {
    attrs: {
      "open": _vm.modals.delete,
      "title": "Delete File",
      "text": "You are about to delete file: <strong>".concat(_vm.modals.delete.name, "</strong>")
    },
    on: {
      "close": function close($event) {
        _vm.modals.delete = false;
      },
      "submit": _vm.onDeleteCreditNote
    }
  }), _c('ConfirmModal', {
    attrs: {
      "open": !!_vm.modals.pdf,
      "title": "PDF Download"
    },
    on: {
      "close": function close($event) {
        _vm.modals.pdf = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_c('button', {
          staticClass: "btn btn-sm btn-light",
          attrs: {
            "type": "button"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              _vm.modals.pdf = false;
            }
          }
        }, [_vm._v("Close")]), _c('button', {
          staticClass: "btn btn-sm btn-primary",
          attrs: {
            "type": "button",
            "data-bs-dismiss": "modal"
          },
          on: {
            "click": function click($event) {
              return _vm.downloadCreditNote('stampedUrl');
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-stamp fa-fw"
        }), _vm._v(" Stamped ")]), _c('button', {
          staticClass: "btn btn-sm btn-primary",
          attrs: {
            "type": "button",
            "data-bs-dismiss": "modal"
          },
          on: {
            "click": function click($event) {
              return _vm.downloadCreditNote('url');
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-file-invoice fa-fw"
        }), _vm._v(" Original ")])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "d-flex mb-3"
  }, [_vm._v("Choose which PDF you would like to download")])])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "spinner-border spinner-lg text-secondary mb-4",
    attrs: {
      "role": "status"
    }
  }, [_c('span', {
    staticClass: "sr-only"
  }, [_vm._v("Loading files...")])]), _c('div', {
    staticClass: "font-w600"
  }, [_vm._v("Loading files...")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', [_c('i', {
    staticClass: "fa fa-xmark-circle mr-1"
  }), _vm._v(" Close ")]);
}]

export { render, staticRenderFns }